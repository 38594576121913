@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  background-color: black;
}
* {
  margin: 0px;
  padding: 0px;
  scrollbar-width: thin;
  color: white;
}

/* .text-bg-blue {

  animation: bg-blue-animation 9s infinite;
}
.text-bg-purple {
  animation: bg-purple-animation 9s infinite;
}

.text-bg-orange {
  animation: bg-orange-animation 9s infinite;
} */

.btn-bg-blue {
  background-image: linear-gradient(to right, #0083ee, #20cbcf);
  padding: 3px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 30px 0px #20cbcf;
  -moz-box-shadow: 0px 0px 30px 0px #20cbcf;
  box-shadow: 0px 0px 30px 0px #20cbcf;
}
.btn-bg-purple {
  background-image: linear-gradient(to right, #7c25bf, #f30386);
  padding: 3px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 30px 0px #f30386;
  -moz-box-shadow: 0px 0px 30px 0px #f30386;
  box-shadow: 0px 0px 30px 0px #f30386;
}
.btn-bg-orange {
  background-image: linear-gradient(to right, #f15347, #fab62e);
  padding: 3px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 30px 0px #fab62e;
  -moz-box-shadow: 0px 0px 30px 0px #fab62e;
  box-shadow: 0px 0px 30px 0px #fab62e;
}
.btn-bg-green {
  background-image: linear-gradient(to right, #069101, #64fa32);
  padding: 3px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 30px 0px #64fa32;
  -moz-box-shadow: 0px 0px 30px 0px #64fa32;
  box-shadow: 0px 0px 30px 0px #64fa32;
}

.text-bg-blue {
  background: -webkit-linear-gradient(#0083ee, #20cbcf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transition: all;
}
.text-bg-purple {
  background: -webkit-linear-gradient(#7c25bf, #f30386);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transition: all;
}
.text-bg-orange {
  background: -webkit-linear-gradient(#f15347, #fab62e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all;
}
.text-bg-green {
  background: -webkit-linear-gradient(#069101, #64fa32);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all;
}

@keyframes bg-blue-animation {
  0% {
    background: -webkit-linear-gradient(#0083ee, #20cbcf);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  33% {
    background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
  }
}

@keyframes bg-purple-animation {
  0% {
  }
  34% {
    background: -webkit-linear-gradient(#7c25bf, #f30386);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  66% {
    background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
  }
}
@keyframes bg-orange-animation {
  0% {
  }
  67% {
    background: -webkit-linear-gradient(#f15347, #fab62e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
    background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.code-font {
  font-family: Consolas, monaco, monospace;
}

* {
  font-family: "Inter";
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #5c5c5c;
  text-align: left;
  padding: 8px;
}

/* about us animation */
.about-us-animation {
  animation: about-us-ball 5s linear 0s infinite;
}

@keyframes about-us-ball {
  0% {
    transform: translateX(0px);
    opacity: 0;
  }
  5% {
    transform: translateX(100px);
    opacity: 10%;
  }
  10% {
    transform: translateX(200px);
    opacity: 20%;
  }
  15% {
    transform: translateX(300px);
    opacity: 35%;
  }
  20% {
    transform: translateX(400px);
    opacity: 40%;
  }
  25% {
    transform: translateX(500px);
    opacity: 55%;
  }
  30% {
    transform: translateX(600px);
    opacity: 80%;
  }
  35% {
    transform: translateX(700px);
    opacity: 95%;
  }
  40% {
    transform: translateX(720px);
    opacity: 100%;
  }
  50% {
    transform: translateX(740px);
    opacity: 100%;
  }
  60% {
    transform: translateX(760px);
    opacity: 100%;
  }
  70% {
    transform: translateX(780px);
    opacity: 100%;
  }
  75% {
    transform: translateX(800px);
    opacity: 100%;
  }
  80% {
    transform: translateX(900px);
    opacity: 80%;
  }
  85% {
    transform: translateX(1000px);
    opacity: 60%;
  }
  90% {
    transform: translateX(1200px);
    opacity: 40%;
  }
  95% {
    transform: translateX(1400px);
    opacity: 20%;
  }
  100% {
    transform: translateX(1500px);
    opacity: 0%;
  }
}

.git-window {
  font-size: 10px;
  z-index: -2;
}
@media only screen and (max-width: 1024px) {
  .git-window {
    font-size: 8px;
  }
}
@media only screen and (max-width: 603px) {
  .git-window {
    font-size: 6px;
  }
}

.saas-gear {
  animation: spin 6s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.saas-gear-opp {
  animation: spin 4s linear 0s infinite reverse;
}
